import { createTheme } from '@material-ui/core/styles';

const mainPrimary = '#009EE2';
const mainSecondary = '#F19001';
const textPrimary = '#4D4D4F';

// Set the base theme settings
const theme = createTheme({
  spacing: 5,
  palette: {
    primary: {
      main: mainPrimary
    },
    secondary: {
      main: mainSecondary
    },
    warning: {
      main: mainSecondary
    },
    success: {
      main: '#70d884'
    },
    background: {
      default: '#FFF',
      paper: '#FFF'
    },
    text: {
      primary: textPrimary,
      secondary: '#A2A4A7'
    }
  },
  typography: {
    // https://www.sitepoint.com/understanding-and-using-rem-units-in-css/
    htmlFontSize: 10,
    fontFamily: 'Montserrat, Arial, sans-serif',
    fontSize: 16,
    h1: {
      fontSize: '5rem',
      fontWeight: 600
    },
    h2: {
      fontSize: '3.1rem',
      fontWeight: 600
    },
    h3: {
      fontSize: '2.4rem',
      fontWeight: 600
    },
    h4: {
      fontSize: '2rem',
      fontWeight: 600
    },
    h5: {
      fontSize: '1.6rem',
      fontWeight: 600
    },
    h6: {
      fontSize: '1.3rem',
      fontWeight: 600
    },
    subtitle1: {
      fontSize: '1.6rem',
      fontWeight: 500,
      lineHeight: 1.6
    },
    subtitle2: {
      fontSize: '1.3rem',
      fontWeight: 500,
      lineHeight: 1.4
    },
    body1: {
      fontSize: '1.6rem',
      fontWeight: 400
    },
    body2: {
      fontSize: '1.3rem',
      fontWeight: 400
    },
    caption: {
      fontSize: '1.2rem',
      fontWeight: 300
    }
  }
});

// Ovveride the MUI components
const preset = createTheme({
  ...theme,
  overrides: {
    MuiCssBaseline: {
      '@global': {
        html: {
          height: '100%',
          width: '100%',
          // https://www.sitepoint.com/understanding-and-using-rem-units-in-css/
          fontSize: '62.5%',
          minWidth: '320px'
        },
        body: {
          height: '100%',
          '& #root': {
            height: '100%'
          }
        },
        small: {
          fontSize: '0.75em'
        },
        strong: {
          fontWeight: theme.typography.fontWeightBold
        },
        em: {
          fontStyle: 'normal',
          textDecorationColor: mainPrimary
        },
        a: {
          textDecoration: 'none',
          color: textPrimary
        },
        del: {
          textDecorationColor: theme.palette.secondary.main
        },
        // Helpers
        '.hidden': {
          display: 'none !important',
          visibility: 'hidden'
        },
        '.t-center': {
          textAlign: 'center'
        }
      }
    },
    // Typography
    MuiTypography: {
      gutterBottom: {
        marginBottom: '0.5em',
        [theme.breakpoints.down('sm')]: {
          marginBottom: '0.25em'
        }
      }
    },
    // Buttons
    MuiButton: {
      root: {
        padding: theme.spacing(0, 2),
        borderRadius: 0,
        boxShadow: 'none',
        fontSize: theme.typography.fontSize + 1,
        fontWeight: theme.typography.fontWeightBold,
        textTransform: 'uppercase'
      },
      text: {
        border: '0'
      },
      sizeLarge: {
        padding: theme.spacing(1, 4)
      },
      outlined: {
        padding: theme.spacing(0, 2),
        borderColor: theme.palette.grey[500],
        color: theme.palette.grey[500],
        '&:hover': {
          backgroundColor: theme.palette.grey[500],
          color: theme.palette.common.white
        }
      },
      outlinedPrimary: {
        backgroundColor: 'inherit',
        borderColor: theme.palette.primary.main,
        '&:hover': {
          backgroundColor: theme.palette.primary.main,
          color: theme.palette.getContrastText(theme.palette.primary.main)
        }
      },
      outlinedSecondary: {
        backgroundColor: 'inherit',
        borderColor: theme.palette.secondary.main,
        '&:hover': {
          backgroundColor: theme.palette.secondary.main
        }
      },
      contained: {
        borderWidth: '1px',
        borderStyle: 'solid',
        boxShadow: 'none',
        backgroundColor: 'rgba(0, 0, 0, 0.8)',
        color: theme.palette.common.white,
        '&:hover': {
          backgroundColor: 'rgba(255, 255, 255, 0.8)',
          color: theme.palette.grey[800]
        }
      },
      containedPrimary: {
        borderColor: theme.palette.primary.main,
        '&:hover': {
          color: theme.palette.common.white
        }
      },
      containedSecondary: {
        borderColor: theme.palette.secondary.main,
        color: theme.palette.common.white,
        '&:hover': {
          color: theme.palette.common.white
        }
      }
    },
    // Forms
    MuiFormControl: {
      root: {
        '&.disableUnderline .MuiInput-underline': {
          '&:hover:not(.Mui-disabled)::before': {
            borderBottom: 0
          },
          '&::before': {
            border: 0
          },
          '&::after': {
            border: 0
          }
        },
        '&.placeholder > .MuiInputBase-root': {
          color: theme.palette.grey[400],
          [theme.breakpoints.down('sm')]: {
            color: theme.palette.grey[600]
          }
        }
      }
    },
    MuiInputBase: {
      root: {
        width: '100%',
        backgroundColor: theme.palette.common.white,
        fontSize: '1.4rem',
        fontWeight: theme.typography.fontWeightRegular,
        borderRadius: 0,
        '&.placeholder > *': {
          color: theme.palette.grey[400]
        },
        [theme.breakpoints.down('sm')]: {
          '&.placeholder > *': {
            color: theme.palette.grey[600]
          }
        }
      },
      input: {
        padding: theme.spacing(2)
      }
    },
    MuiOutlinedInput: {
      root: {
        borderRadius: 0
      },
      input: {
        padding: theme.spacing(2)
      },
      notchedOutline: {
        '&.borderless': {
          border: 'none'
        }
      }
    },
    MuiSelect: {
      root: {
        fontSize: '1.4rem',
        padding: theme.spacing(2),
        fontWeight: theme.typography.fontWeightRegular
      },
      icon: {
        fontSize: 24
      }
    },
    MuiBadge: {
      badge: {
        fontSize: '1rem'
      }
    },
    MuiGrid: {
      item: {
        lineHeight: '1rem'
      }
    },
    MuiFormHelperText: {
      root: {
        '&.filled': {
          margin: 0,
          padding: theme.spacing(1),
          color: `${theme.palette.common.white} !important`,
          backgroundColor: theme.palette.error.main
        }
      }
    }
  }
});

export default preset;
