import { AuthenticationDetails, User } from 'authentication/auth';
import React from 'react';

export interface AuthContextState {
  login: (user: any) => Promise<boolean>;
  logout: () => void;
  refreshUser: (jwt: string) => Promise<boolean>;
  authenticationDetails: AuthenticationDetails;
  updateUserInSession: (user: User) => void;
}

export interface AppContextState {
  authContext: AuthContextState;
}

const AppContext = React.createContext<AppContextState>({
  authContext: {
    login: () => Promise.resolve(false),
    logout: () => {},
    updateUserInSession: () => {},
    refreshUser: () => Promise.reject(),
    authenticationDetails: { authenticated: false, jwt: '' }
  }
});

export default AppContext;
