import axios, { AxiosRequestConfig } from 'axios';
import configs from 'configs';
import ReactGA from 'react-ga';

export const baseUrl = `${configs.strapi.uri}:${configs.strapi.port}`;

const post = (
  url: string,
  data: Record<string, string | number | Date | boolean> | FormData | {},
  jwt: string | null,
  configExtra?: AxiosRequestConfig
) => {
  const config: AxiosRequestConfig = {
    headers: {
      'CF-Access-Client-Id': configs.clientId,
      'CF-Access-Client-Secret': configs.clientSecret
    }
  };

  if (jwt) {
    config.headers.Authorization = `Bearer ${jwt}`;
  }

  ReactGA.event({
    category: 'API Request',
    action: 'Post',
    label: `${baseUrl}/${url}`
  });

  return axios.post(`${baseUrl}/${url}`, data, { ...config, ...configExtra });
};

export const put = (url: string, data: Record<string, string | number | Date | boolean | string[] | {}> | {}, jwt: string | null) => {
  const config: AxiosRequestConfig = {
    headers: {
      'CF-Access-Client-Id': configs.clientId,
      'CF-Access-Client-Secret': configs.clientSecret
    }
  };

  if (jwt) {
    config.headers.Authorization = `Bearer ${jwt}`;
  }

  ReactGA.event({
    category: 'API Request',
    action: 'Put',
    label: `${baseUrl}/${url}`
  });

  return axios.put(`${baseUrl}/${url}`, data, config);
};

export const doDelete = (url: string, jwt: string | null) => {
  const config: AxiosRequestConfig = {
    headers: {
      'CF-Access-Client-Id': configs.clientId,
      'CF-Access-Client-Secret': configs.clientSecret
    }
  };

  if (jwt) {
    config.headers.Authorization = `Bearer ${jwt}`;
  }
  return axios.delete(`${baseUrl}/${url}`, config);
};

export const get = (url: string, jwt: string | null) => {
  const config: AxiosRequestConfig = {
    headers: {
      'CF-Access-Client-Id': configs.clientId,
      'CF-Access-Client-Secret': configs.clientSecret
    }
  };

  if (jwt) {
    config.headers.Authorization = `Bearer ${jwt}`;
  }

  ReactGA.event({
    category: 'API Request',
    action: 'Get',
    label: `${baseUrl}/${url}`
  });

  return axios.get(`${baseUrl}/${url}`, config);
};

export const connectProvider = (provider: string) => {
  window.location.href = `${baseUrl}/connect/${provider}`;
};

export default post;
