import { AuthenticationDetails, User } from 'authentication/auth';
import axios, { AxiosResponse } from 'axios';
import { eachDayOfInterval, parse } from 'date-fns';
import { ListingFormValues } from 'models/ListingFormValues';
import ReactGA from 'react-ga';
import Booking, { BookingDates, strapiInterval } from './booking';
import FeatureCollection from './city';
import { DiscussionDetail, DiscussionMessage } from './discussion';
import ApiError from './error';
import Event from './event';
import { Favourite } from './favourite';
import Image from './image';
import { LandingPageType } from './landingPageType';
import Language from './language';
import Listing, { PublicListing } from './listing';
import Location from './location';
import Profile from './profile';
import PublicProfile from './publicProfile';
import post, { connectProvider, doDelete, get, put } from './request';
import Review from './review';
import { SinglePageType } from './singlePageType';
import Sport from './sport';
import Community from './community';

class Api {
  static fetchEvents(jwt: string): Promise<Event[]> {
    return get('events', jwt).then((response: AxiosResponse<Event[]>) => response.data);
  }

  static fetchNextEvents(jwt: string): Promise<Event[]> {
    return get('events/next/12', jwt).then((response: AxiosResponse<Event[]>) => response.data);
  }

  static fetchLocations(jwt: string): Promise<Location[]> {
    return get('locations', jwt).then((response: AxiosResponse<Location[]>) => response.data);
  }

  static fetchProfile(id: number, jwt: string): Promise<Profile> {
    return get(`profiles/${id}`, jwt).then((response: AxiosResponse<Profile>) => response.data);
  }

  static saveProfile(jwt: string, profile: Profile): Promise<Profile> {
    return put(
      `profiles/${profile.id}`,
      {
        ...profile
      },
      jwt
    )
      .then((response: AxiosResponse<Profile>) => response.data)
      .finally(() => {
        ReactGA.event({
          category: 'User',
          action: 'Save Profile'
        });
      });
  }

  static createProfile(jwt: string, profile: FormData): Promise<Profile> {
    return post('profiles', profile, jwt)
      .then((response: AxiosResponse<Profile>) => response.data)
      .finally(() => {
        ReactGA.event({
          category: 'Registration',
          action: 'Create Profile'
        });
      });
  }

  static fetchListing(id: number, jwt: string): Promise<PublicListing | string> {
    return get(`listings/getPublicListing/${id}`, jwt).then((response: AxiosResponse<PublicListing | string>) => response.data);
  }

  static fetchListingByUserId(userId: number | undefined, jwt: string): Promise<Listing | undefined> {
    return get(`listings/getByUser/${userId}`, jwt).then((response: AxiosResponse<Listing | undefined>) => response.data);
  }

  static fetchCalendar(listingId: number | undefined, jwt: string): Promise<BookingDates> {
    const reducer = (acc: Date[], interval: strapiInterval) => {
      const start = parse(interval.start, 'yyyy-MM-dd', Date.now());
      const end = parse(interval.end, 'yyyy-MM-dd', Date.now());
      return [...acc, ...eachDayOfInterval({ start, end })];
    };
    return get(`bookings/getBookingDates/${listingId}`, jwt).then((response: AxiosResponse<BookingDates>) => ({
      ...response.data,
      projectedDates: response.data.bookingDates.reduce(reducer, [])
    }));
  }

  static getMyCalendar(listingId: number | undefined, jwt: string): Promise<BookingDates> {
    const reducer = (acc: Date[], interval: strapiInterval) => {
      const start = parse(interval.start, 'yyyy-MM-dd', Date.now());
      const end = parse(interval.end, 'yyyy-MM-dd', Date.now());
      return [...acc, ...eachDayOfInterval({ start, end })];
    };
    return get(`bookings/getMyBookingDates/${listingId}`, jwt).then((response: AxiosResponse<BookingDates>) => ({
      ...response.data,
      projectedDates: response.data.bookingDates.reduce(reducer, [])
    }));
  }

  static fetchReviews(userId: number, jwt: string): Promise<Review[]> {
    return get(`reviews/getMyReviews/${userId}`, jwt).then((response: AxiosResponse<Review[]>) => response.data);
  }

  private static getRandomInt(max: number, min: number) {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }

  static fetchBookings(jwt: string): Promise<Booking[]> {
    return get(`bookings/getCurrent/${Api.getRandomInt(0, 1000)}`, jwt).then((response: AxiosResponse<Booking[]>) => response.data);
  }

  static fetchSport(jwt: string): Promise<Sport[]> {
    return get('sports', jwt || null).then((response: AxiosResponse<Sport[]>) => response.data);
  }

  static fetchPublicProfile(jwt: string, profileId: number): Promise<PublicProfile> {
    return get(`profiles/getPublicProfile/${profileId}`, jwt).then((response: AxiosResponse<PublicProfile>) => response.data);
  }

  static fetchDiscussion(jwt: string, code: string): Promise<DiscussionDetail> {
    return get(`discussions/get/${code}`, jwt).then((response: AxiosResponse<DiscussionDetail>) => response.data);
  }

  static fetchDiscussions(jwt: string): Promise<DiscussionMessage[]> {
    return get('discussions/getDiscussions/a', jwt).then((response: AxiosResponse<DiscussionMessage[]>) => response.data);
  }

  static getNewMessageCount(jwt: string): Promise<number> {
    return get('messages/count/false', jwt).then((response: AxiosResponse<number>) => response.data);
  }

  static markAsRead(jwt: string, id: number, read: boolean): Promise<void> {
    return put(
      `messages/read/${id}`,
      {
        read
      },
      jwt
    ).then();
  }

  static createNewBookingRequest(
    jwt: string,
    listing: number,
    user: number,
    Start: Date,
    End: Date,
    Guests: number,
    message: string
  ): Promise<string> {
    return post(
      'bookings/createNewBookingRequest',
      {
        listing,
        user,
        Start,
        End,
        Guests,
        message
      },
      jwt
    )
      .then((response: AxiosResponse<string>) => response.data)
      .finally(() => {
        ReactGA.event({
          category: 'Booking',
          action: 'Send Booking Request',
          label: `Listing ID: ${listing}`
        });
      });
  }

  static updateListing(jwt: string, listing: number, formValues: ListingFormValues): Promise<Listing> {
    return put(
      `listings/${listing}`,
      {
        ...formValues
      },
      jwt
    ).then((response: AxiosResponse<Listing>) => response.data);
  }

  static updateListingCoverImage(jwt: string, imageId: number): Promise<string> {
    return put(`listings/updateCoverImage/${imageId}`, {}, jwt).then((response: AxiosResponse<string>) => response.data);
  }

  static removeImage(jwt: string, imageId: number): Promise<string> {
    return doDelete(`upload/files/${imageId}`, jwt).then((response: AxiosResponse<string>) => response.data);
  }

  static sendMessage(jwt: string, from: number, to: number, Content: string, code: string, discussion: number): Promise<void> {
    return post(
      'messages',
      {
        sender: from,
        receiver: to,
        Content,
        CreatedOn: new Date(Date.now()),
        emailSent: false,
        read: false,
        DiscussionReference: code,
        discussion
      },
      jwt
    )
      .then((response: AxiosResponse<void>) => response.data)
      .finally(() => {
        ReactGA.event({
          category: 'User',
          action: 'Send Message',
          label: `From UserId: ${from}`
        });
      });
  }

  static fetchSingleTypeContent(page: string, jwt?: string): Promise<SinglePageType> {
    return get(page, jwt || null).then((response: AxiosResponse<SinglePageType>) => response.data);
  }

  static fetchLandingPageTypeContent(page: string, jwt?: string): Promise<LandingPageType> {
    return get(page, jwt || null).then((response: AxiosResponse<LandingPageType>) => response.data);
  }

  static uploadFile(entity: string, entityId: number, field: string, formData: FormData, jwt?: string): Promise<Image[]> {
    formData.append('ref', entity);
    formData.append('refId', entityId.toString());
    formData.append('field', field);
    return post('upload', formData, jwt || null).then((response: AxiosResponse<Image[]>) => response.data);
  }

  static sendContactUsEnquiry(firstName: string, lastName: string, country: string, email: string, message: string, jwt?: string): Promise<void> {
    return post(
      'contact-us/enquiry',
      {
        firstName,
        lastName,
        country,
        email,
        message
      },
      jwt || null
    )
      .then((response: AxiosResponse<void>) => response.data)
      .finally(() => {
        ReactGA.event({
          category: 'User',
          action: 'Send Enquiry'
        });
      });
  }

  static sendAdminEmail(subject: string, message: string, jwt?: string): Promise<void> {
    return post(
      'contact-us/admin',
      {
        subject,
        message
      },
      jwt || null
    ).then((response: AxiosResponse<void>) => response.data);
  }

  static rejectBooking(jwt: string, bookingId: number, reason: string): Promise<number> {
    return put(
      `bookings/reject/${bookingId}`,
      {
        reason
      },
      jwt
    )
      .then((response: AxiosResponse<number>) => response.data)
      .finally(() => {
        ReactGA.event({
          category: 'Booking',
          action: 'Reject Booking Request'
        });
      });
  }

  static acceptBooking(jwt: string, bookingId: number, text: string): Promise<number> {
    return put(`bookings/accept/${bookingId}`, { text }, jwt)
      .then((response: AxiosResponse<number>) => response.data)
      .finally(() => {
        ReactGA.event({
          category: 'Booking',
          action: 'Accept Booking Request',
          label: `BookingId: ${bookingId}`
        });
      });
  }

  static reportAbuse(jwt: string, bookingId: number, reason: string): Promise<number> {
    return put(
      `bookings/report/${bookingId}`,
      {
        reason
      },
      jwt
    )
      .then((response: AxiosResponse<number>) => response.data)
      .finally(() => {
        ReactGA.event({
          category: 'Booking',
          action: 'Report Abuse'
        });
      });
  }

  static searchListing(
    jwt: string,
    query: string,
    _startDate: string,
    _endDate: string,
    _favourite: string,
    isRefugee: boolean
  ): Promise<PublicListing[]> {
    const promise = isRefugee ? get(`listings/searchByR/${_startDate}/${_endDate}/`, jwt) : get(`listings/searchBy/${query}`, jwt);
    // TODO: pass start end and favourite
    return promise
      .then((response: AxiosResponse<PublicListing[]>) => response.data)
      .finally(() =>
        ReactGA.event({
          category: 'Search',
          action: 'Search for host'
        })
      );
  }

  static registerUserLocal(email: string, password: string): Promise<AuthenticationDetails> {
    const username = email;
    return post('auth/local/register', { username, email, password }, null)
      .then((response: AxiosResponse<AuthenticationDetails>) => ({
        user: response.data.user,
        jwt: response.data.jwt,
        authenticated: true
      }))
      .catch((error: ApiError) => {
        throw new Error(error.response.data.message[0].messages[0].id);
      })
      .finally(() =>
        ReactGA.event({
          category: 'Authentication',
          action: 'Registration',
          label: 'Email & Password'
        })
      );
  }

  static registerUserProvider(provider: string): void {
    connectProvider(provider);
  }

  static authenticateUserProvider(provider: string, params: string): Promise<AuthenticationDetails> {
    return get(`auth/${provider}/callback${params}`, null)
      .then((response: AxiosResponse<AuthenticationDetails>) => ({
        user: response.data.user,
        jwt: response.data.jwt,
        authenticated: true
      }))
      .finally(() => {
        ReactGA.event({
          category: 'Authentication',
          action: 'Auth Provider',
          label: provider
        });
      });
  }

  static getFavouriteByParentId(jwt: string, parentId: number): Promise<Favourite[]> {
    return get(`favourites?ParentId=${parentId}`, jwt).then((response: AxiosResponse<Favourite[]>) => response.data);
  }

  static getUserFavourites(jwt: string, userId?: number): Promise<Favourite[]> {
    return get(`favourites/getByUserId/${userId}`, jwt)
      .then((response: AxiosResponse<Favourite[]>) => response.data)
      .catch(() => Promise.resolve([]));
  }

  static getFavouritesByIds(jwt: string, ids: string[]): Promise<Favourite[]> {
    return get(`favourites?${ids.map(i => `id_in=${i}`).join('&')}`, jwt).then((response: AxiosResponse<Favourite[]>) => response.data);
  }

  static addFavourite(jwt: string, favourites: string[], user: string): Promise<boolean> {
    return put(`users/${user}`, { favourites }, jwt).then(() => true);
  }

  static createListing(jwt: string, listing: FormData): Promise<boolean> {
    return post('listings', listing, jwt)
      .then(() => true)
      .finally(() => {
        ReactGA.event({
          category: 'Registration',
          action: 'Complete Listing'
        });
      });
  }

  static acceptTOS(id: number, jwt: string): Promise<boolean> {
    return put(
      `users/${id}`,
      {
        acceptedTOS: true
      },
      jwt
    )
      .then(() => true)
      .finally(() => {
        ReactGA.event({
          category: 'Registration',
          action: 'Accepted TOS'
        });
      });
  }

  static deleteAccount(jwt: string): Promise<boolean> {
    return doDelete('users/removeMe', jwt)
      .then((r: AxiosResponse<boolean>) => r.data)
      .finally(() => {
        ReactGA.event({
          category: 'User',
          action: 'Delete Account'
        });
      });
  }

  static setIsHost(isHost: boolean, jwt: string): Promise<boolean> {
    return get('users/me', jwt).then((u: AxiosResponse<User>) => put(`users/${u.data.id}`, { isNotHost: !isHost }, jwt).then(() => true));
  }

  static setIsRefugee(isRefugee: boolean, jwt: string): Promise<boolean> {
    return get('users/me', jwt).then((u: AxiosResponse<User>) => put(`users/${u.data.id}`, { isRefugee }, jwt).then(() => true));
  }

  static deleteListing(jwt: string): Promise<boolean> {
    return doDelete('listings/removeMe', jwt).then((r: AxiosResponse<boolean>) => r.data);
  }

  static setActive(isActive: boolean, jwt: string): Promise<boolean> {
    return put('listings/setActive', { isActive }, jwt).then((r: AxiosResponse<boolean>) => r.data);
  }

  static setUserLanguage(jwt: string, language: string): Promise<void | AxiosResponse> {
    return get('users/me', jwt).then((u: AxiosResponse<User>) => put(`users/${u.data.id}`, { Language: language }, jwt));
  }

  static sendReview(review: Record<string, string | Date | number>, bookingId: number, isGuest: boolean, jwt: string): Promise<boolean> {
    return post('reviews/', review, jwt).then(() =>
      put(
        `bookings/${bookingId}`,
        {
          GuestReviewedOn: isGuest ? new Date() : null,
          HostReviewedOn: isGuest ? null : new Date()
        },
        jwt
      )
        .then(() => true)
        .finally(() => {
          ReactGA.event({
            category: 'Booking',
            action: 'Send Review',
            label: isGuest ? 'From guest' : 'From host'
          });
        })
    );
  }

  static getLoggedInUser(jwt: string): Promise<User> {
    return get('users/me', jwt).then((u: AxiosResponse<User>) => u.data);
  }

  static saveFirebaseToken(jwt: string, token: string): Promise<string> {
    return put(`users/saveToken/${token}`, {}, jwt).then(() => token);
  }

  static getCities(query: string, language: string = 'en'): Promise<FeatureCollection> {
    return axios
      .get(`https://api.maptiler.com/geocoding/[${query}].json?key=${process.env.REACT_APP_MAPTILER_ACCESS_TOKEN}&language=${language}`)
      .then((u: AxiosResponse<FeatureCollection>) => u.data);
  }

  static getLanguages(jwt: string): Promise<Language[]> {
    return get('languages', jwt).then((response: AxiosResponse<Language[]>) => response.data);
  }

  static sendForgotPasswordEnquiry(email: string) {
    return post(
      'auth/forgot-password',
      {
        email
      },
      null
    )
      .then(() => true)
      .catch(() => true)
      .finally(() => {
        ReactGA.event({
          category: 'User',
          action: 'Send Forgot Password Enquiry'
        });
      });
  }

  static sendResetPasswordEnquiry(code: string, password: string, passwordConfirmation: string) {
    return post(
      'auth/reset-password',
      {
        code,
        password,
        passwordConfirmation
      },
      null
    )
      .then(() => true)
      .finally(() => {
        ReactGA.event({
          category: 'User',
          action: 'Send Reset Password Enquiry'
        });
      });
  }

  static registerToCommunity(jwt: string, code: string): Promise<{ success: boolean; community: Community; message: string | undefined }> {
    return put(`communities/register/${code}`, {}, jwt).then(
      (response: AxiosResponse<{ success: boolean; community: Community; message: string | undefined }>) => response.data
    );
  }

  static unregisterFromCommunity(jwt: string, id: string): Promise<boolean> {
    return put(`communities/unregister/${id}`, {}, jwt)
      .then(() => true)
      .catch(() => false);
  }

  static setCommunityOnly(communityOnly: boolean, jwt: string): Promise<boolean> {
    return get('users/me', jwt).then((u: AxiosResponse<User>) => put(`users/${u.data.id}`, { communityOnly }, jwt).then(() => true));
  }

  static getCommunity(code: string): Promise<{ success: boolean; community?: Community }> {
    return get(`communities/byCode/${code}`, null).then((response: AxiosResponse<{ success: boolean; community?: Community }>) => response.data);
  }

  static getCommunityAndMembers(id: string, jwt: string): Promise<{ success: boolean; community?: Community; members: PublicProfile[] }> {
    return get(`communities/withMembers/${id}`, jwt).then(
      (response: AxiosResponse<{ success: boolean; community?: Community; members: PublicProfile[] }>) => response.data
    );
  }

  static getCommunities(jwt: string): Promise<Community[]> {
    return get('communities', jwt).then((response: AxiosResponse<Community[]>) => response.data);
  }
}

export default Api;
