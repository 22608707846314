/* eslint-disable no-console */
import Api from 'api';
import firebase from 'firebase/app';
import 'firebase/messaging';
import { isIOS, isSafari } from 'react-device-detect';

const config = {
  apiKey: process.env.REACT_APP_APIKEY,
  authDomain: process.env.REACT_APP_AUTHDOMAIN,
  databaseURL: process.env.REACT_APP_DB,
  projectId: process.env.REACT_APP_PID,
  storageBucket: process.env.REACT_APP_SB,
  messagingSenderId: process.env.REACT_APP_SID,
  appId: process.env.REACT_APP_APPID
};

export interface PushNotificationPayload {
  data: {
    title: string;
    body: string;
  };
}

firebase.initializeApp(config);
const messaging = !isSafari && !isIOS && firebase.messaging();

const requestFirebaseNotificationPermission = () => messaging && Notification.requestPermission().then(() => messaging.getToken());

export const onMessageListener = () =>
  messaging &&
  new Promise<PushNotificationPayload>(resolve => {
    messaging.onMessage(payload => {
      resolve(payload as PushNotificationPayload);
    });
  });

const INSTANCE_TOKEN = 'FIREBASE_INSTANCE_TOKEN';

export const checkFirebaseNotificationPermission = async (jwt: string) => {
  let permissionGranted = false;
  try {
    /* request permission if not granted */
    if (Notification.permission !== 'granted') {
      await requestFirebaseNotificationPermission();
    }
    /* get instance token if not available */
    if (localStorage.getItem(INSTANCE_TOKEN) !== null) {
      permissionGranted = true;
    } else {
      const token = await requestFirebaseNotificationPermission();
      if (token) {
        await Api.saveFirebaseToken(jwt, token);
        localStorage.setItem(INSTANCE_TOKEN, token);
        permissionGranted = true;
      }
    }
  } catch (err) {
    console.log(err);
    if (err.hasOwnProperty('code') && err.code === 'messaging/permission-default') console.log('You need to allow the site to send notifications');
    else if (err.hasOwnProperty('code') && err.code === 'messaging/permission-blocked')
      console.log('Currently, the site is blocked from sending notifications. Please unblock the same in your browser settings');
    else console.log('Unable to subscribe you to notifications');
  }
  return permissionGranted;
};
