import * as Icons from '@material-ui/icons';
import { RouteCategories, RouteItems, RoutePositions } from './base';

const routes: RouteItems = [
  {
    nameId: 'Mood Board',
    key: 'routes.stub.name.moodBoard',
    scene: 'MoodBoard',
    category: RouteCategories.MAIN,
    position: RoutePositions.HEADER,
    path: '/mood-board',
    exact: true,
    private: true,
    hidden: true
  },
  {
    nameId: 'ForgottenPassword',
    key: 'routes.stub.name.forgottenPassword',
    scene: 'ForgotPassword',
    category: RouteCategories.MAIN,
    position: RoutePositions.HEADER,
    path: '/forgot-password',
    exact: true,
    private: undefined,
    hidden: true
  },
  {
    nameId: 'ResetPassword',
    key: 'routes.stub.name.resetPassword',
    scene: 'ResetPassword',
    category: RouteCategories.MAIN,
    position: RoutePositions.HEADER,
    path: '/reset-password',
    private: undefined,
    hidden: true,
    params: '/:code?'
  },
  {
    nameId: 'Auth',
    key: 'routes.stub.name.auth',
    category: RouteCategories.MAIN,
    position: RoutePositions.HEADER,
    scene: 'LandingPage',
    layout: 'single',
    private: false,
    hidden: true,
    path: '/auth',
    params: '/:provider?'
  },
  {
    nameId: 'Messages',
    key: 'routes.stub.name.messages',
    scene: 'Messages',
    layout: 'wide',
    category: RouteCategories.MAIN,
    position: RoutePositions.HEADER,
    path: '/messages',
    private: true
  },
  {
    nameId: 'Communities',
    key: 'routes.stub.name.communities',
    scene: 'Communities',
    layout: 'wide',
    category: RouteCategories.MAIN,
    position: RoutePositions.HEADER,
    path: '/communities',
    private: true
  },
  {
    nameId: 'Calendar',
    key: 'routes.stub.name.calendar',
    scene: 'Calendar',
    layout: 'full',
    category: RouteCategories.MAIN,
    position: RoutePositions.HEADER,
    private: true,
    path: '/calendar',
    params: '/:id?'
  },
  {
    nameId: '',
    key: 'routes.stub.name.home',
    category: RouteCategories.MAIN,
    position: RoutePositions.HEADER,
    scene: 'Home',
    layout: 'full',
    path: '/',
    exact: true,
    private: true,
    hidden: false
  },
  {
    nameId: 'Discussion',
    key: 'routes.stub.name.discussion',
    scene: 'Discussion',
    layout: 'wide',
    category: RouteCategories.MAIN,
    position: RoutePositions.HEADER,
    private: true,
    path: '/discussion',
    params: '/:code',
    hidden: true
  },
  {
    nameId: 'Search Results',
    key: 'routes.stub.name.searchResults',
    scene: 'SearchResults',
    layout: 'full',
    category: RouteCategories.MAIN,
    position: RoutePositions.HEADER,
    path: '/search-results',
    private: true,
    hidden: true
  },
  {
    nameId: 'Listing',
    key: 'routes.stub.name.listing',
    scene: 'Listing',
    layout: 'full',
    category: RouteCategories.ACTION,
    position: RoutePositions.HEADER,
    path: '/listing',
    private: true
  },
  {
    nameId: 'View Profile',
    key: 'routes.stub.name.viewProfile',
    scene: 'ViewProfile',
    layout: 'full',
    category: RouteCategories.ACTION,
    position: RoutePositions.HEADER,
    path: '/view-profile',
    private: true,
    params: '/:id?',
    hidden: true
  },
  {
    nameId: 'View Listing',
    key: 'routes.stub.name.viewListing',
    scene: 'ViewListing',
    layout: 'full',
    category: RouteCategories.ACTION,
    position: RoutePositions.HEADER,
    path: '/view-listing',
    private: true,
    params: '/:id?',
    hidden: true
  },
  {
    nameId: 'View Community',
    key: 'routes.stub.name.viewCommunity',
    scene: 'ViewCommunity',
    layout: 'full',
    category: RouteCategories.ACTION,
    position: RoutePositions.HEADER,
    path: '/view-community',
    private: true,
    params: '/:id?',
    hidden: true
  },
  {
    nameId: 'Review',
    key: 'routes.stub.name.review',
    scene: 'Review',
    layout: 'wide',
    category: RouteCategories.ACTION,
    position: RoutePositions.HEADER,
    path: '/review',
    private: true,
    params: '/:code',
    hidden: true
  },
  {
    nameId: 'Profile',
    key: 'routes.stub.name.profile',
    scene: 'Profile',
    layout: 'full',
    category: RouteCategories.ACTION,
    position: RoutePositions.HEADER,
    path: '/profile',
    private: true,
    params: '/:id?'
  },
  {
    nameId: 'Logout',
    key: 'routes.stub.name.logout',
    scene: 'Logout',
    category: RouteCategories.ACTION,
    position: RoutePositions.HEADER,
    path: '/logout',
    private: true
  },
  {
    nameId: 'ContactUs',
    key: 'routes.stub.name.contactUs',
    scene: 'ContactUs',
    category: RouteCategories.MAIN,
    position: RoutePositions.FOOTER,
    path: '/contact-us',
    private: undefined
  },
  {
    nameId: 'Terms and Conditions',
    key: 'routes.stub.name.termsConditions',
    scene: 'TermsAndConditions',
    category: RouteCategories.MAIN,
    position: RoutePositions.FOOTER,
    path: '/terms-and-conditions',
    private: undefined
  },
  {
    nameId: 'Privacy Policy',
    key: 'routes.stub.name.privacyPolicy',
    scene: 'PrivacyPolicy',
    category: RouteCategories.MAIN,
    position: RoutePositions.FOOTER,
    path: '/privacy-policy',
    private: undefined
  },
  {
    nameId: 'Licenses',
    key: 'routes.stub.name.licenses',
    scene: 'Licenses',
    category: RouteCategories.MAIN,
    position: RoutePositions.FOOTER,
    path: '/licenses',
    private: undefined
  },
  {
    nameId: '',
    key: '',
    externalLink: 'https://www.facebook.com/fc4fansfr/',
    category: RouteCategories.SOCIAL,
    position: RoutePositions.FOOTER,
    private: undefined,
    Icon: Icons.Facebook
  },
  {
    nameId: '',
    key: '',
    externalLink: 'https://www.youtube.com/channel/UCJSYVF_ZPSMbjCikf2Rj6zA',
    category: RouteCategories.SOCIAL,
    position: RoutePositions.FOOTER,
    private: undefined,
    Icon: Icons.YouTube
  },
  {
    nameId: '',
    key: '',
    externalLink: 'https://twitter.com/Fc4fans_fr',
    category: RouteCategories.SOCIAL,
    position: RoutePositions.FOOTER,
    private: undefined,
    Icon: Icons.Twitter
  },
  {
    nameId: '',
    key: '',
    externalLink: 'https://www.instagram.com/fc4fans_fr/',
    category: RouteCategories.SOCIAL,
    position: RoutePositions.FOOTER,
    private: undefined,
    Icon: Icons.Instagram
  }
];

export default routes;
