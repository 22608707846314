import { SvgIconComponent } from '@material-ui/icons';

export enum RouteCategories {
  MAIN = 'MAIN',
  ACTION = 'ACTION',
  SOCIAL = 'SOCIAL'
}

export enum RoutePositions {
  HEADER = 'HEADER',
  FOOTER = 'FOOTER'
}

// single: applies its own layout structure.
// full: occupy entire width of screen
// default: with a max width
export type RouteLayoutOptions = 'full' | 'wide' | 'default' | 'single';

export interface RouteItem {
  nameId: string;
  key: string;
  category: RouteCategories;
  position: RoutePositions;
  private: boolean | undefined;
  scene?: string;
  layout?: RouteLayoutOptions;
  path?: string;
  exact?: boolean;
  Icon?: SvgIconComponent;
  badge?: number;
  hidden?: boolean;
  externalLink?: string;
  params?: string;
}

export type RouteItems = Array<RouteItem>;
