import DateFnsUtils from '@date-io/date-fns';
import { CssBaseline } from '@material-ui/core';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { ThemeProvider } from '@material-ui/styles';
import useLogin from 'authentication/auth';
import Loader from 'components/Loader';
import AppContext, { AppContextState } from 'context/app';
import RouteContext from 'context/route';
import React, { lazy, Suspense, useMemo } from 'react';
import ReactGA from 'react-ga';
import { BrowserRouter } from 'react-router-dom';
import navItems from 'routes/stub';
import getTheme from 'themes/themeBuilder';

ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_ID || '', {
  debug: process.env.NODE_ENV !== 'production',
  testMode: process.env.NODE_ENV !== 'production'
});

const Layout = lazy(async () => {
  return import('./layouts');
});

const App: React.FC = () => {
  return (
    <Suspense fallback={<Loader />}>
      <Layout />
    </Suspense>
  );
};

const ConnectedApp: React.FC = () => {
  const { restoreAuthentication, authenticationDetails, login, logout, refreshUser, updateUserInSession } = useLogin();

  useMemo(() => {
    restoreAuthentication();
  }, [restoreAuthentication]);

  const refresh = (jwt: string) => {
    return refreshUser(jwt);
  };

  const initState: AppContextState = {
    authContext: {
      authenticationDetails,
      login,
      logout,
      refreshUser: refresh,
      updateUserInSession
    }
  };

  return (
    <AppContext.Provider value={initState}>
      <BrowserRouter basename={process.env.REACT_APP_PUBLIC_URL || '/'}>
        <RouteContext.Provider value={navItems}>
          <ThemeProvider theme={getTheme('default')}>
            <CssBaseline />
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <App />
            </MuiPickersUtilsProvider>
          </ThemeProvider>
        </RouteContext.Provider>
      </BrowserRouter>
    </AppContext.Provider>
  );
};

export { ConnectedApp };

export default App;
