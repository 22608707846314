import { Box, CircularProgress, LinearProgress } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import React from 'react';

type Loaders = 'circle' | 'skeleton' | 'default';
interface Props {
  type?: Loaders;
}

const Loader = (props: Props) => {
  const { type } = props;
  switch (type) {
    case 'circle':
      return (
        <Box mb={2}>
          <CircularProgress variant="indeterminate" disableShrink size={30} thickness={4} />
        </Box>
      );
    case 'skeleton':
      return (
        <Box mb={2}>
          <Skeleton />
          <Skeleton />
          <Skeleton />
        </Box>
      );
    default:
      return (
        <Box mb={2}>
          <LinearProgress variant="query" />
          <LinearProgress variant="query" color="secondary" />
        </Box>
      );
  }
};

export default Loader;
