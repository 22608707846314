/* eslint-disable camelcase */
import Api from 'api';
import { Favourite } from 'api/favourite';
import Listing from 'api/listing';
import Profile from 'api/profile';
import post from 'api/request';
import clone from 'lodash/clone';
import { checkFirebaseNotificationPermission } from 'push/firebase';
import { useCallback, useState } from 'react';
import ReactGA from 'react-ga';
import Community from 'api/community';

export interface User {
  email: string;
  id: number;
  username: string;
  provider: string;
  confirmed: number;
  blocked?: boolean;
  role: Object;
  profile: Profile;
  listing: Listing;
  created_at: Date;
  updated_at: Date;
  favourites: Favourite[];
  acceptedTOS: boolean;
  isNotHost: boolean;
  language: string;
  isRefugee: boolean;
  communityOnly: boolean;
  communities: Community[];
}

export interface AuthenticationDetails {
  authenticated: boolean;
  user?: User;
  jwt: string;
}

const TOKEN_KEY = 'jwtToken';
const USER_INFO = 'userInfo';

const useLogin = () => {
  const initState: AuthenticationDetails = { authenticated: false, jwt: '' };
  const [authenticationDetails, setAuthenticationDetails] = useState(initState);

  const clear = () => {
    if (sessionStorage && sessionStorage.getItem(TOKEN_KEY)) {
      sessionStorage.removeItem(TOKEN_KEY);
    }

    if (sessionStorage && sessionStorage.getItem(USER_INFO)) {
      sessionStorage.removeItem(USER_INFO);
    }
    if (authenticationDetails.authenticated) {
      const authDetails = {
        authenticated: false,
        jwt: ''
      };
      setAuthenticationDetails(authDetails);
    }
  };

  const setAuthentication = (authentication: AuthenticationDetails) => {
    if (!authentication) {
      return;
    }

    if (sessionStorage) {
      sessionStorage.setItem(TOKEN_KEY, authentication?.jwt || '');
      sessionStorage.setItem(USER_INFO, JSON.stringify(authentication?.user));
    }

    setAuthenticationDetails(authentication);
  };

  const updateProfile = (authentication: AuthenticationDetails, profile: Profile) => {
    if (!profile || !authentication?.authenticated) {
      return;
    }

    const newAuthenticationDetails = clone(authentication);
    newAuthenticationDetails.user = {
      ...newAuthenticationDetails.user,
      profile
    } as User;

    setAuthentication(newAuthenticationDetails);
  };

  const refreshUser = async (jwt: string): Promise<boolean> => {
    if (!jwt) {
      return Promise.reject();
    }

    return Api.getLoggedInUser(jwt).then(u => {
      const authDet = {
        authenticated: true,
        user: u,
        jwt
      };
      setAuthentication(authDet);
      window.location.replace('/');

      return true;
    });
  };

  const updateUserInSession = (user: User) => {
    sessionStorage.setItem(USER_INFO, JSON.stringify(user));
  };

  const restoreAuthentication = useCallback(() => {
    if (sessionStorage) {
      const user = sessionStorage.getItem(USER_INFO) || '';
      const jwt = sessionStorage.getItem(TOKEN_KEY) || '';

      return setAuthenticationDetails({
        authenticated: jwt !== '',
        user: user !== '' ? (JSON.parse(user) as User) : undefined,
        jwt
      });
    }

    return setAuthenticationDetails({
      authenticated: false,
      jwt: ''
    });
  }, []);

  const login = async (user: any): Promise<boolean> => {
    return post(
      'auth/local',
      {
        identifier: user.identifier,
        password: user.password
      },
      null
    )
      .then(response => {
        const details: AuthenticationDetails = {
          jwt: response.data.jwt,
          user: response.data.user,
          authenticated: true
        };

        checkFirebaseNotificationPermission(response.data.jwt);

        setAuthentication(details);
        return true;
      })
      .catch(() => false)
      .finally(() => {
        ReactGA.event({
          category: 'Authentication',
          action: 'Login',
          label: 'Email & Password'
        });
      });
  };

  const logout = () => {
    clear();
  };

  return {
    login,
    authenticationDetails,
    logout,
    restoreAuthentication,
    updateProfile,
    refreshUser,
    setAuthentication,
    updateUserInSession
  };
};

export default useLogin;
