import { Theme } from '@material-ui/core';
import defaultPreset from './presets/default';

const presets: {
  [key: string]: Theme;
} = {
  default: defaultPreset
};

export default presets;
