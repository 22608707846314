/* eslint-disable no-process-env */
interface UriConfig {
  uri: string;
  port: number | string;
}

interface Configs {
  app: UriConfig;
  env: string | undefined;
  strapi: UriConfig;
  clientSecret: string;
  clientId: string;
}

const configs: Configs = {
  app: {
    port: process.env.REACT_APP_PORT || 3000,
    uri: process.env.REACT_APP_BASE_URL || 'https://localhost'
  },
  env: process.env.NODE_ENV,
  strapi: {
    port: process.env.REACT_APP_DB_PORT || 1337,
    uri: process.env.REACT_APP_DB_BASE_URL || 'http://localhost'
  },
  clientSecret: process.env.REACT_APP_CLIENT_SECRET || '',
  clientId: process.env.REACT_APP_CLIENT_ID || ''
};
export default configs;
/* eslint-enable no-process-env */
